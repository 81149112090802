import CryptoJS from 'crypto-js';
//AES加密
function encryptAES(data) {
  //如果参数为空或者为空对象就不生成签名
  if (!data || JSON.stringify(data) === '{}') {
    return '';
  }
  //   获取参数的所有键，并按照ASCII码排序
  let keys = Object.keys(data).sort();
  let sortedParamsString = keys
    .map(
      (key) =>
        `${key}=${
          typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key]
        }`
    )
    .join('&');

  // 遍历排序后的键数组，使用键从原始对象中获取对应的值，并使用'&'符号将它们拼接起来
  return AES(sortedParamsString);
}
/** 加密函数
 * @param {Object} data
 */
function AES(data) {
  // 密钥与偏移量若与服务端有交互，则需确保它们与服务端使用的是相同的。
  // 密钥
  const secretKey = CryptoJS.enc.Utf8.parse('8985b2c4fb1900cf');
  // 偏移量
  const iv = CryptoJS.enc.Utf8.parse('bc3f07fbe54c4b90');
  let encryptedParams = typeof data === 'string' ? data : JSON.stringify(data);
  let encrypted = CryptoJS.AES.encrypt(encryptedParams, secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
}
export default {
  encryptAES,
};
