import http from "@/assets/http.js";
import request from "@/assets/request.js";
export default {
    /**
     * 获取商家店铺订单列表
     * @pageNum    当前页数
     * @pageSize   分页条数
     */
    reqActiveGoods(data) {
        return http({
            url: "/api/Activity/pageMarketing",
            method: "post",
            data,

        })
    },
    // 添加商品列表
    reqAddGoods(data) {
        return http({
            url: "/api/Activity/selectMarketingShopProduct",
            method: "post",
            data,
        });
    },
    // 添加审核
    reqAddGoodsAuditing(data) {
        return request({
            url: "/api/Activity/addActivityProduct",
            method: "post",
            data,

        });
    },
    // 查看活动详情
    reqGoodsDetail(data) {
        return http({
            url: "/api/Activity/selectInfoByActivityId",
            method: "post",
            data,

        });
    },
    //查看申请记录
    reqApplicationRecords(data) {
        return http({
            url: "/api/Activity/selectRecordByActivityId",
            method: "post",
            data,

        });
    },
    //查看活动数据列表
    reqActiveData(data) {
        return request({
            url: "/activity/queryActivityList",
            method: "post",
            data,

        });
    },
    //查看统计数据+
    reqDataStatistics(data) {
        return request({
            url: "/activity/queryDataStatistics",
            method: "post",
            data,


        });
    },
    //查看商品数据
    reqShopData(data) {
        return request({
            url: "/activity/queryProductStatisticsList",
            method: "post",
            data,

        });
    },
    // 秒杀订单列表
    reqFlashOrderList(data) {
        return request({
            url: "/api/Activity/pageFlashSaleOrder",
            method: "post",
            data,

        });
    },
    // 秒杀订单列表
    reqRankList(data) {
        return request({
            url: "/activity/queryProductStatisticsRank",
            method: "post",
            data,

        });
    },
    // 交易总额折线图数据
    reqLineChartAmount(data) {
        return request({
            url: "/activity/queryTradeAmountLineChart",
            method: "post",
            data,

        });
    },
    // 交易订单折线图数据
    reqLineChartOrder(data) {
        return request({
            url: "/activity/queryTradeOrderLineChart",
            method: "post",
            data,

        });
    },
    // 交易用户折线图数据
    reqLineChartUser(data) {
        return request({
            url: "/activity/queryTradeUserLineChart",
            method: "post",
            data,

        });
    },
};