//几种常用vue快速开发模版简介： https://www.cnblogs.com/lzh-blod/p/12966586.html
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import api from '@/assets/api.js';
import dict from '@/assets/dict.js';
import help from '@/assets/help.js';
import http from '@/assets/http.js';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
// 全局方法挂载
import { formatSubForm, clearForm, formatJson } from '@/utils/tools';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/base.scss';

Vue.use(ElementUI, { size: 'small' }); //设置控件的默认值
Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$dict = dict;
Vue.prototype.$help = help;
Vue.prototype.$http = http;
Vue.prototype.$formatSubForm = formatSubForm;
Vue.prototype.$clearForm = clearForm;
Vue.prototype.formatJson = formatJson; // 参数过
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.prototype.color = {
  // theme: "#ff5000", //主题色
  theme: '#ffcd42', //主题色
  bgPage: '#F5F6F6', //页面背景色
  // bgPage: "#0078d4", //页面背景色
  bgMask: 'rgba(0, 0, 0, 0.5)', //遮罩背景颜色
  bgMain: '#333333',
  bgSide: '#393939',
  textMain: '#333333',
  textSide: '#666666',
  textLight: '#999999',
  textLighter: '#cccccc',
};

// 全局注册基础组件
const requireComponent = require.context(
  './components/base', // 其组件目录的相对路径
  false, // 是否查询其子目录
  /Base[A-Z]\w+\.(vue|js)$/ // 匹配基础组件文件名的正则表达式
);
Vue.filter('dictFormat', function (data, value) {
  const obj = data.find((item) => item.code == value);
  return obj && obj.text;
});

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    ) // 获取和目录深度无关的文件名
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

let app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default app;
