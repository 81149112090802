/**
 * 此处放置订单相关api
 */
import http from "@/assets/http.js";
import request from "@/assets/request.js";
export default {
    /**
     * 获取商家店铺订单列表
     * @pageNum    当前页数
     * @pageSize   分页条数
     */
    shopOrderList(data) {
        return http({
            url: "/api/backstage/shopOrderList",
            method: "post",
            data,
        });
    },
    // 查商户钱包余额
    queryShopWallet(data) {
        return http({
            url: "/api/slbOrder/queryShopWalletByUserId",
            method: "post",
            data,
        });
    },
    // 提现
    shopWithdrawal(data) {
        return http({
            url: "/api/slbOrder/updateShopTXMoney",
            method: "post",
            data,
        });
    },
    /**
     * @id    订单ID
     * @result  审核结果
     * @msg  审核意见
     * 退款申请商家审核(此处有防重复提交)
     */
    auditRefundOrder(data) {
        return http({
            url: "/api/shop/auditRefundOrderByBusiness",
            method: "post",
            data,
        });
    },
    /**
     * @id    订单ID
     * 查询用户订单详情
     */
    orderDetails(data) {
        return http({
            url: "/api/slborder/orderDetails",
            method: "post",
            data,
        });
    },
    /**
     * @shopId    商铺ID
     * 统计商家应结算总金额，已结款总金额
     */
    settlementOrder(data) {
        return http({
            url: "/api/finance/settlementOrder",
            method: "post",
            data,
        });
    },

    /**
     * @day    时间( 1 是当天， 30 是当月)
     * @shopId 商铺ID
     * 订单数据统计
     */
    statisticsOrder(data) {
        return http({
            url: "/api/finance/statisticsOrder",
            method: "post",
            data,
        });
    },
    /**
     * 获取快递公司列表(不需要token)
     */
    slbemsEmsList(data) {
        return http({
            url: "/api/common/slbems/emsList",
            method: "post",
            data,
        });
    },
    // 确认发货(此处有防重复提交)
    confirmShopOrder(data) {
        return http({
            url: "api/slborder/confirmShopOrder",
            method: "post",
            data,
        });
    },
    /**
     * @shopId 商铺ID
     * 统计商家有效订单及有效订单总额
     */
    validOrder(data) {
        return http({
            url: "/api/finance/validOrder",
            method: "post",
            data,
        });
    },
    /**
     * @number 物流单号
     * 根据物流单号查询物流信息
     */
    queryWLInfo(data) {
        return http({
            url: '/api/slborder/queryWLInfo',
            method: 'post',
            data
        })
    },
    /**
     * 更改物流
     */
    updateOrderEms(data) {
        return http({
            url: '/api/order/updateOrderEms',
            method: 'post',
            data
        })
    },
    //分页获取厂商订单
    getRestockList(data) {
        return http({
            // url: 'api/slborder/ymOrderList',
            url: 'api/backstage/ymOrderList',
            method: 'post',
            data
        })
    },
    //厂商订单--确认入仓
    confirmInHold(data) {
        return http({
            url: '/api/common/Center/confirmInHold',
            method: 'post',
            data
        })
    },
    //获取物流信息
    acquiringLogisticsInfo(data) {
        return http({
            url: '/api/slborder/acquiringLogisticsInfo',
            method: 'post',
            data
        })
    },
    queryShipNumber(data) {
        return http({
            url: '/api/order/queryShipNumber',
            method: 'post',
            data
        })
    },
    emsList(data) {
        return http({
            url: 'api/common/slbems/emsList',
            method: 'post',
            data
        })
    },

    /**
     * @description: 修改收件人地址
     * @Date: 2022-10-06 14:02:11
     * @author: cc
     * @param {*} data
     */
    updateReceiveAddress(data) {
        return http({
            url: '/api/slborder/updateConsigneeInfo',
            method: 'post',
            data
        })
    },
};


export function afterSalePage(data) {
    // 退货退款列表
    return request({
        url: `/api/afterSale/page`,
        method: "post",
        ContentType: 'application/json',
        data
    });
}

export function agreeReturn(data) {
    // 同意退款
    return request({
        url: `/api/afterSale/agreeReturn`,
        method: "post",
        ContentType: 'application/json',
        data
    });
}

export function agreeReturnGoods(data) {
    // 同意退货
    return request({
        url: `/api/afterSale/agreeReturnGoods`,
        method: "post",
        ContentType: 'application/json',
        data
    });
}

export function rejectReturn(data) {
    // 拒绝退款
    return request({
        url: `/api/afterSale/rejectReturn`,
        method: "post",
        ContentType: 'application/json',
        data
    });
}

export function rejectReturnGoods(data) {
    // 拒绝退货
    return request({
        url: `/api/afterSale/rejectReturnGoods`,
        method: "post",
        ContentType: 'application/json',
        data
    });
}

export function getDetails(data) {
    // 售后详情
    return request({
        url: `/api/afterSale/getDetails`,
        method: "post",
        ContentType: 'application/json',
        data
    });
}



